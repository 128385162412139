import { RawBuilder, sql } from "kysely";

import { queryBuilder } from "./db";

export function getOwners() {
  return queryBuilder.selectFrom("mz_roles as r").select((eb) => [
    "r.id",
    "r.name",
    eb
      .or([
        sql<boolean>`(${hasSuperUserPrivileges()})`,
        eb.fn<boolean>("has_role", [
          sql.id("current_user"),
          "r.oid",
          sql.lit("USAGE"),
        ]),
      ])
      .$castTo<boolean>()
      .as("isOwner"),
  ]);
}

// Note(SangJunBak): This is a helper function to convert an expression to a JSON array.
// We need to explicitly type the output and cannot generically use the type of the
// input expression. This is because the expression becomes converted into a jsonb and we cannot
// implicitly convert the jsonb back to its original types on runtime.
export function jsonArrayFrom<T, R = unknown>(expr: R): RawBuilder<T[]> {
  return sql`(select coalesce(jsonb_agg(agg), '[]') from ${expr} as agg)`;
}

// NOTE(benesch): We do not have ideal handling for
// multiprocess clusters (i.e., 2xlarge+ clusters at the time of writing) in
// the Console. Specifically, we merge metrics across processes as if they were a single process.
// Ideally it would return data for each process in
// the replica separately, but the downstream consumers (e.g., the replica
// table) are not yet equipped to handle that.
// A better fix should be handled here (https://github.com/MaterializeInc/console/issues/1041)
export function buildClusterReplicaUtilizationTable(
  {
    mzClusterReplicaUtilization = "mz_cluster_replica_utilization",
  }: {
    mzClusterReplicaUtilization?: "mz_cluster_replica_utilization";
  } = {
    mzClusterReplicaUtilization: "mz_cluster_replica_utilization",
  },
) {
  return queryBuilder
    .selectFrom(`${mzClusterReplicaUtilization} as cru`)
    .groupBy(["replica_id", "process_id"])
    .select([
      "replica_id",
      sql<number | null>`SUM(cru.cpu_percent) / COUNT(process_id)`.as(
        "cpu_percent",
      ),
      // Because processes have the same total memory size,
      // we're able to sum all memory percentages and divide by the number of processes. Assuming two processes,
      // this is equivalent to (process_1_memory + process_2_memory) / (process_1_total_memory + process_2_total).
      // We can assume if a process is offline, we'll still have a row for it that contains null values.
      sql<number | null>`SUM(cru.memory_percent) / COUNT(process_id)`.as(
        "memory_percent",
      ),
      sql<number | null>`SUM(cru.disk_percent) / COUNT(process_id)`.as(
        "disk_percent",
      ),
    ]);
}

/**
 * Useful since Kysely's count function returns a union of all number types in TypeScript
 */
export function countAll() {
  return sql<bigint>`count(*)`.as("count");
}

/**
 * This is important for flexible deployment mode where users aren't superusers by default
 * but need superuser privileges. RBAC is disabled by default in flexible deployment mode
 * so we can identify flexible deployment mode by checking the system variable `enable_rbac_checks`
 */
export function hasSuperUserPrivileges() {
  return sql.raw<boolean>(
    `SELECT mz_is_superuser() OR current_setting('enable_rbac_checks') = 'off'`,
  );
}

/**
 *
 * Represents Postgres and MySQL tables for pre and post source versioning. Also represents Kafka and Webhook sources post source versioning.
 */
export function buildSourceDependenciesQuery(sourceId: string) {
  return (
    queryBuilder
      // We use mz_object_dependencies instead of mz_tables and mz_sources since mz_tables is not a retained metrics object yet (https://github.com/MaterializeInc/materialize/pull/30788).
      .selectFrom("mz_object_dependencies as od")
      .leftJoin("mz_sources as subsources", "object_id", "subsources.id")
      .where("referenced_object_id", "=", sourceId)
      .where((eb) =>
        eb.or([
          eb("subsources.type", "<>", "progress"),
          // When the source dependency is a table, subsources.type is null.
          eb("subsources.type", "is", null),
        ]),
      )
      .select(["od.object_id as id", "od.referenced_object_id as sourceId"])
  );
}
