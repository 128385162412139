import { QueryKey } from "@tanstack/react-query";
import { sql } from "kysely";

import { executeSqlV2, queryBuilder } from "~/api/materialize";

export function buildLargestClusterReplicaQuery(clusterId: string) {
  return (
    queryBuilder
      .selectFrom("mz_cluster_replicas as cr")
      .innerJoin("mz_cluster_replica_sizes as crs", "cr.size", "crs.size")
      .leftJoin(
        // Sometimes system indexes on user clusters (e.g. introspection indexes)
        // do not hydrate. Thus we filter them out first.
        queryBuilder
          .selectFrom("mz_hydration_statuses")
          .select(["replica_id", "hydrated"])
          .where("object_id", "not like", "s%")
          .as("hs"),
        "cr.id",
        "hs.replica_id",
      )
      .select((eb) => [
        "cr.name",
        "cr.size",
        eb
          .cast<number>(
            sql<number>`${eb.ref("crs.memory_bytes")} * ${eb.ref("crs.processes")}`,
            "numeric",
          )
          .as("memoryBytes"),
        sql<boolean>`bool_and(hs.hydrated)`.as("isHydrated"),
      ])
      .where("cr.cluster_id", "=", clusterId)
      .groupBy(["cr.name", "cr.size", "crs.memory_bytes", "crs.processes"])
      // Prioritize fully hydrated clusters first, then order by memory
      .orderBy("isHydrated", sql`desc NULLS LAST`)
      .orderBy("memoryBytes", "desc")
      .limit(1)
  );
}

export type LargestClusterReplicaParams = {
  clusterId: string;
};

/**
 * Fetches the largest cluster replica for a given cluster.
 */
export async function fetchLargestClusterReplica({
  params,
  queryKey,
  requestOptions,
}: {
  params: LargestClusterReplicaParams;
  queryKey: QueryKey;
  requestOptions?: RequestInit;
}) {
  const compiledQuery = buildLargestClusterReplicaQuery(
    params.clusterId,
  ).compile();
  return executeSqlV2({
    queries: compiledQuery,
    queryKey: queryKey,
    requestOptions,
  });
}
