import { Tag } from "@chakra-ui/react";
import { useAtom } from "jotai";
import * as React from "react";

import { CopyButton } from "~/components/copyableComponents";
import { appConfig } from "~/config/AppConfig";
import { withConfigCheck } from "~/hoc/withAppConfig";
import { useCurrentUser } from "~/queries/frontegg";
import { currentEnvironmentState } from "~/store/environments";
import { isMzInternalEmail } from "~/util";

const { mzConsoleImageTag } = appConfig;

const VersionTag = ({
  label,
  contents,
  title,
}: {
  label: string;
  contents: string;
  title: string;
}) => {
  return (
    <Tag size="sm" variant="outline" opacity={0.8} fontSize="80%">
      {label}
      <CopyButton
        contents={contents}
        size="xs"
        ml={1}
        title={title}
        height="auto"
      />
    </Tag>
  );
};

const LoadingVersionTag = () => {
  return (
    <Tag size="sm" variant="outline" opacity={0.8}>
      ...
    </Tag>
  );
};

export const OrgTag = withConfigCheck(
  () => {
    const { data: user } = useCurrentUser();
    if (!isMzInternalEmail(user.email) || !user.tenantId) return null;

    const truncatedOrgId = user.tenantId.slice(0, 9) + "...";

    return (
      <VersionTag
        label={`Org ID ${truncatedOrgId}`}
        contents={user.tenantId}
        title="Copy org ID"
      />
    );
  },
  ({ isFlexibleDeployment }) => !isFlexibleDeployment,
);

export const ConsoleImageTag = withConfigCheck(
  () => {
    if (!mzConsoleImageTag) return null;

    return (
      <VersionTag
        label={mzConsoleImageTag}
        contents={mzConsoleImageTag}
        title="Copy console image ref"
      />
    );
  },
  ({ isFlexibleDeployment }) => isFlexibleDeployment,
);

export const EnvironmentdImageRefTag = withConfigCheck(
  () => {
    const [environment] = useAtom(currentEnvironmentState);

    const mzEnvironmentdImageRef =
      environment?.state === "enabled" &&
      environment.status.health === "healthy"
        ? `materialize/environmentd:${environment.status.version.crateVersion.version}`
        : null;

    if (!mzEnvironmentdImageRef) return <LoadingVersionTag />;

    return (
      <VersionTag
        label={mzEnvironmentdImageRef}
        contents={mzEnvironmentdImageRef}
        title="Copy environmentd image ref"
      />
    );
  },
  ({ isFlexibleDeployment }) => isFlexibleDeployment,
);

export const HelmChartVersionTag = withConfigCheck(
  () => {
    const [environment] = useAtom(currentEnvironmentState);

    if (
      environment?.state !== "enabled" ||
      environment.status.health !== "healthy"
    ) {
      return <LoadingVersionTag />;
    }

    // If there is no helm chart version, like in the Emulator, we want to show nothing.
    if (environment.status.version.helmChartVersion === undefined) {
      return null;
    }

    const mzHelmChartVersion = `v${environment.status.version.helmChartVersion.version}`;

    return (
      <VersionTag
        label={`Helm ${mzHelmChartVersion}`}
        contents={mzHelmChartVersion}
        title="Copy helm chart version"
      />
    );
  },
  ({ isFlexibleDeployment }) => isFlexibleDeployment,
);
